import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <Link
        to="/"
        css={logo}
      >
        {siteTitle}
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const logo = css`
  margin: 40px 0;
  display: inline-block;
  font-size: 30px;
  font-family: ${constants.font1};
  padding-bottom: 8px; 
  font-weight: 800;
  text-decoration: none;
  letter-spacing: 2px;

  :after {
    content: "";
    display: block;
    width: 85px;
    height: 4px;
    margin-top: 10px;
    background-color: ${constants.secondary};
    border-radius: ${constants.radiusMax};
  }
`