import React from 'react'
import { Link } from 'gatsby'

import { css } from '@emotion/core'
import { constants } from '../styles/constants'

import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa"

const Footer = () => {

    return (
        <footer css={main} className='container'>
            <div css={meta}>
                © {new Date().getFullYear()}, Tous droits réservés lyketil.com
                <br />
                <a href="https://lyketil.com/fr/">Agence digitale créative à Lausanne et Suisse romande</a>
                <br />
                <Link to="freelances">Freelance Vidéo ? Rejoignez-notre réseau en Suisse romande</Link>
            </div>
            <div css={social}>
                <div><a href="https://www.facebook.com/lyketil" rel="nofollow noreferrer noopener" target="_blank"><FaFacebook /></a></div>
                <div><a href="https://www.instagram.com/lyketil/" rel="nofollow noreferrer noopener" target="_blank"><FaInstagram /></a></div>
                <div><a href="https://ch.linkedin.com/company/lyketil" rel="nofollow noreferrer noopener" target="_blank"><FaLinkedinIn /></a></div>
            </div>
        </footer>
    )
}

export default Footer

const main = css`
    padding: 80px 0 100px;
    font-size: 12px;
`

const meta = css`
    a {
        text-decoration: none;
    }
`

const social = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;

    div {
        font-size: 26px;
        margin-right: 20px;

        a {
            :hover {
                background-color: transparent;

                svg {
                    color: ${constants.secondary};
                    background-color: transparent;
                }
            }
        }
    }
`