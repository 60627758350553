export const constants = {
    font1: `'Playfair Display', serif`,
    font2: `'Open Sans', monospace`,
    black: `#000000`,
    grey: `#8C8C8C`,
    lightgrey: `#F6F6F6`,
    white: `#fff`,
    primary: `#00D1FF`,
    secondary: `#FFFF00`,
    radiusMax: `200px`,
    radiusMedium: `15px`,
    radiusSmall: `5px`,
    gradient1: `linear-gradient(to right, #00D1FF 0%, #00FF47 100%)`,
    shadowBig: `0px 2px 18px 10px rgba(50,50,93,0.06)`,
    breakpoint1: `800px`,
    breakpoint2: `1060px`,
}